export const columns = [
{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 50,
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'index'}
},
  {
    title: '所属经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width:150,
    align:'center',
    ellipsis:true
  },
  {
    title: '所属门店',
    dataIndex: 'storeName',
    key: 'storeName',
    width:150,
    align:'center',
    ellipsis:true
  },
  {
    title: '所属员工',
    dataIndex: 'managerIdName',
    key: 'managerIdName',
    width:100,
    align:'center',
    ellipsis:true
  },
{
    title: '客户姓名',
    dataIndex: 'name',
    key: 'name',
    width:100,
    align:'center',
    ellipsis:true
},
{
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '地址',
    dataIndex: 'province',
    key: 'province',
    width:150,
    align:'center',
    // ellipsis:true
    scopedSlots: { customRender: 'province'}
},
{
    title: '客户类型',
    dataIndex: 'title',
    key: 'title',
    width:100,
    align:'center',
    ellipsis:true
},
{
    title: '客户来源',
    dataIndex: 'customerSource',
    key: 'customerSource',
    width:150,
    align:'center',
    // ellipsis:true
  scopedSlots: {customRender: 'customerSource'}
},
{
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    width:100,
    align:'center',
    // ellipsis:true,
  scopedSlots: { customRender: 'orderType'}
},
{
    title: '预订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width:100,
    align:'center',
    // ellipsis:true,
  scopedSlots: { customRender: 'orderStatus'}
},
  {
    title: '申请状态',
    dataIndex: 'registrationStatus',
    key: 'registrationStatus',
    width:80,
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'registrationStatus'}
  },
  {
    title: '平台来源',
    dataIndex: 'sourceType',
    key: 'sourceType',
    width:100,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'sourceType'}
  },
{
    title: '操作日期',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    width:150,
    align: 'center',
},

]
